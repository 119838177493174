<template>
  <myCenterLayout
    select="order"
    :pageTitle="
      languagePackage[language].userV2_right_right_myOrder_orderInfo_title
    "
  >
    <orderDetails></orderDetails>
  </myCenterLayout>
</template>

<script>
import myCenterLayout from "@/components/layout/myCenter/index.vue";
import orderDetails from "./c/orderDetails.vue";
import languagePackage from "@/languagePackage/index.js";
export default {
  components: {
    myCenterLayout,
    orderDetails,
  },
  data() {
    return {
      languagePackage,
    };
  },
  computed: {
    language() {
      return this.$store.getters.language;
    },
  },
};
</script>

<style lang="less" scoped>
</style>