<template>
  <div class="order-info" :class="'skin_theme_' + skin_theme">
    <dialogQr ref="dialogQr" @closeCallback="closeCallback" />

    <div class="title pd">
      <div class="line"></div>
      <span>
        {{
          languagePackage[language].userV2_right_right_My_order_details_title
        }}
      </span>
    </div>
    <div class="order-content pd" v-loading="loading">
      <el-row :gutter="20">
        <el-col :span="8"
          ><div class="grid-content bg-purple">
            <span class="spot"></span
            ><span
              >{{
                languagePackage[language]
                  .userV2_right_right_My_order_details_list_title1
              }}：{{ order.order_num }}</span
            >
          </div></el-col
        >
        <el-col :span="8"
          ><div class="grid-content bg-purple">
            <span class="spot"></span
            ><span
              >{{
                languagePackage[language]
                  .userV2_right_right_My_order_details_list_title2
              }}：{{ order.type_f }}</span
            >
          </div></el-col
        >

        <el-col :span="8"
          ><div class="grid-content bg-purple">
            <span class="spot"></span
            ><span
              >{{
                languagePackage[language]
                  .userV2_right_right_My_order_details_list_title3
              }}：{{ order.pay_type_f }}</span
            >
          </div></el-col
        >
        <el-col :span="8"
          ><div class="grid-content bg-purple">
            <span class="spot"></span
            ><span
              >{{
                languagePackage[language]
                  .userV2_right_right_My_order_details_list_title4
              }}：{{ order.create_time_f }}</span
            >
          </div></el-col
        >
        <el-col :span="8"
          ><div class="grid-content bg-purple">
            <span class="spot"></span
            ><span
              >{{
                languagePackage[language]
                  .userV2_right_right_My_order_details_list_title5
              }}：{{ order.pay_time_f }}</span
            >
          </div></el-col
        >
        <el-col :span="24"
          ><div class="grid-content bg-purple">
            <span class="spot"></span
            ><span
              >{{
                languagePackage[language]
                  .userV2_right_right_My_order_details_list_title6
              }}：{{ order.desc }}</span
            >
          </div></el-col
        >
      </el-row>
      <div
        style="padding: 20px"
        v-if="order.status == 1 && order.is_end_time == false"
      >
        <el-button @click="startWxPay(order.order_num)">
          {{
            languagePackage[language]
              .userV2_right_right_My_order_details_list_btn1
          }}
        </el-button>
      </div>
      <div style="padding: 20px">
        <el-button @click="$router.go(-1)">
          {{
            languagePackage[language]
              .userV2_right_right_My_order_details_list_btn2
          }}
        </el-button>
      </div>
    </div>
  </div>
</template>

<script>
import { get_info as get_order_info } from "@/api/order.js";
import dialogQr from "@/components/common/dialogQr.vue";
import { wx_unifiedorder } from "@/api/paywx.js";
import languagePackage from "@/languagePackage/index.js";

export default {
  components: { dialogQr },

  data() {
    return {
      loading: false,
      order: {},
      timer: null,
      languagePackage,
    };
  },
  mounted() {
    let order_num = this.$route.query.order_num;
    this.getOrderInfo(order_num);
  },
  methods: {
    getOrderInfo(order_num) {
      this.loading = true;
      get_order_info({ order_num })
        .then((res) => {
          if (res.code == 200) {
            this.order = res.data.order;
          }
        })
        .catch((err) => {})
        .finally(() => {
          this.loading = false;
        });
    },

    closeCallback() {
      clearInterval(this.timer);
    },
    startWxPay(order_num) {
      let p = {
        order_num,
      };
      this.loading = true;
      wx_unifiedorder(p)
        .then((res) => {
          if (res.code == 200) {
            console.log(res);
            this.dialogVisible = false;
            let code_url = res.data.code_url;
            let fee_f = res.data.fee_f;
            this.$refs.dialogQr.start(code_url, "微信支付(" + fee_f + ")");

            this.startTimer(order_num);
          }
        })
        .catch((err) => {})
        .finally(() => {
          this.loading = false;
        });
    },

    startTimer(order_num) {
      if (this.timer) {
        clearInterval(this.timer);
      }
      this.timer = setInterval(() => {
        get_order_info({ order_num })
          .then((res) => {
            if (res.code == 200 && res.data.order.status == 2) {
              this.endTimer();
              this.getOrderInfo(order_num);
            }
          })
          .catch((err) => {
            this.endTimer();
          })
          .finally(() => {});
      }, 3000);
    },
    endTimer() {
      if (this.timer) {
        clearInterval(this.timer);
      }
      this.timer = null;
      this.$refs.dialogQr.dialogVisible = false;
    },
  },
  computed: {
    language() {
      return this.$store.getters.language;
    },
    skin_theme() {
      return this.$store.getters.skin_theme;
    },
  },
};
</script>

<style lang="less" scoped>
.order-info {
  padding: 1px;
  margin: 20px;
  border-radius: 14px;
  overflow: hidden;

  .pd {
    background: #fff;
    padding: 25px;
  }
  .title {
    font-size: 12px;
    font-weight: 700;
    display: flex;
    align-items: center;
    border-top-left-radius: 14px;
    border-top-right-radius: 14px;
    .line {
      width: 4px;
      height: 12px;
      background: #299ffe;
      margin-right: 10px;
    }
  }
  .order-content {
    border-bottom-left-radius: 14px;
    border-bottom-right-radius: 14px;
    .el-col {
      padding: 12px 0;
      font-size: 12px;
      color: #333;
      .grid-content {
        display: flex;
        align-items: center;
      }
    }
    .spot {
      display: inline-block;
      width: 20px;
      height: 20px;
      //   background: url("~@/assets/imgs/indexRecommendRecommend/spot.png")
      // no-repeat;
      background-position: center;
      background-size: 30%;
    }
  }
  .el-button {
    width: 56px;
    height: 26px;
    padding: 0;
    font-size: 12px;
    border: 0;
    color: #fff;
  }
}

.skin_theme_one.order-info {
  .pd {
    background: #fff;
    padding: 25px;
    box-shadow: 0px 5px 10px 0px rgba(0, 18, 153, 0.2);
  }

  .title {
    border-bottom: 1px solid #f3f3f3;
    color: #333;
    .line {
      background-color: #299ffe;
    }
  }

  .order-content {
    .el-col {
      color: #333;
    }
  }
  .el-button {
    background-color: #4943ff;
    box-shadow: 4px 6px 16px 0px rgba(0, 0, 0, 0.4);
  }
}
.skin_theme_two.order-info {
  background: linear-gradient(
    205deg,
    #24bca8 0%,
    rgba(27, 26, 31, 0.19) 32%,
    #1b1a1f 100%
  );
  .pd {
    background: #232227;
    box-shadow: 4px 6px 16px 0px #000000;
    padding: 25px;
  }
  .title {
    border-bottom: 1px solid #4d4c50;
    color: #fff;
    .line {
      background-color: #42dfc8;
    }
  }

  .order-content {
    .el-col {
      color: #fff;
    }
  }
  .el-button {
    background: linear-gradient(
      201deg,
      #24bca8 0%,
      rgba(27, 26, 31, 0.19) 32%,
      #1b1a1f 100%
    );
    box-shadow: 0px 2px 8px 0px rgba(21, 2, 0, 0.69);
  }
}
</style>